import React  from 'react';
import { Link } from 'react-router-dom';
import { FaEye } from "react-icons/fa";
import { Container } from 'react-bootstrap';
import DataTable from "react-data-table-component";


const VideoList = (props) => {
  const data = [
    {
      'id': 1,
      'Title': 'The Universe Science For Kids Periwinkle',
    },
    {
      'id': 2,
      'Title': 'Writing Alphabet Letters For Children Alphabet for Kids Periwinkle Part 2',
    }
  ];

  const customStyles = {
      rows: {
        style: {
          minHeight: '40px', // override the row height
        },
      },
      headCells: {
        style: {          
          padding: '12px 15px',
          background: '#eaecf4',
          fontFamily: 'Poppins-Bold',
          fontSize: '16px',
          color: '#000',
          borderColor: '#e3e6f0',
        },
      },
      cells: {
        style: {
          padding: '12px 15px',
          background: '#fff',
          fontFamily: 'Poppins',
          fontSize: '15px',
          color: '#606060',
          borderColor: '#e3e6f0',
        },
      },
  };

  const columns = [        
      {
        name: 'id',
        selector: row => row.id,
        sortable: true,

      },
      {
        name: 'Video Title',
        selector: row => row.Title,
        sortable: true,

      },
      {
          name: 'Action',
          selector: row =>  <Link className='btn btn-green' to={'/VideoDetail/'+row.id}><FaEye /></Link>,      
      },
  ];

  return (
    <Container>
        <DataTable
          columns={columns}
          data={data}
          customStyles={customStyles}
          {...props}
        />
    </Container>
  );
}

export default VideoList
