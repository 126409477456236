import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import v1 from '../../assets/video/The_Universe_Science_For_Kids_Periwinkle.mp4';
import v2 from '../../assets/video/Writing_Alphabet_Letters_For_Children_Alphabet_for_Kids_Periwinkle_Part2.mp4';


const VideoDetail = () => {
  const { id } = useParams();
  const videoRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      const video = videoRef.current;
      if (video) {
        const aspectRatio = video.videoWidth / video.videoHeight;
        const windowAspectRatio = window.innerWidth / window.innerHeight;

        if (aspectRatio > windowAspectRatio) {
          video.style.width = '100%';
          video.style.height = 'auto';
        } else {
          video.style.width = 'auto';
          video.style.height = '100%';
        }
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [id]);

  const videoSource = id === '1' ? v1 : v2;
  return (
    <div className='videowapper'>
            <video ref={videoRef} controls>
              <source src={videoSource} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
        </div>  
  )
}

export default VideoDetail
