import React from 'react';
import { Routes, Route } from "react-router-dom";

import VideoList from './VideoList/VideoList';
import VideoDetail from './VideoDetail/VideoDetail';

import '../assets/fonts/font.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import "../assets/style/style.css";
import "../assets/style/responsive.css";

const Main = () => {
    return (
        <>
          <React.Fragment>
            <Routes>
                <Route exact path="/" element={<VideoList />} />
                <Route exact path="/VideoDetail/:id" element={<VideoDetail />} />
            </Routes>
          </React.Fragment>
        </>
      );
}

export default Main